<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#FDCE48"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-dialog v-model="deleteDialogue" width="400">
      <v-card>
        <v-layout pa-4 wrap justify-center>
          <v-flex xs12>
            <span
              style="
                color: #000000;
                font-family: opensanssemibold;
                font-size: 18px;
              "
              >Do you want to delete this Apiary ?</span
            >
          </v-flex>
          <v-flex xs6 px-2 py-4>
            <v-btn
              outlined
              tile
              block
              depressed
              dark
              color="#C62525"
              @click="deleteApiary"
              class="itemValue"
            >
              Yes
            </v-btn>
          </v-flex>
          <v-flex xs6 px-2 py-4>
            <v-btn
              tile
              block
              depressed
              dark
              color="#1FB436"
              @click="deleteDialogue = false"
              class="itemValue"
            >
              No
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-card tile outlined>
          <v-layout wrap justify-center pa-2>
            <v-flex xs12 sm10 md11 xl12 align-self-center>
              <v-layout wrap justify-start>
                <v-flex xs6 sm3 md2 xl4>
                  <v-layout wrap justify-center>
                    <v-flex xs12 text-left>
                      <span class="itemKey">Apiary Id</span>
                    </v-flex>
                    <v-flex xs12 text-left>
                      <span class="itemValue">
                        {{ storage.apiaryid }}
                        <span v-if="!storage.apiaryid">-</span>
                      </span>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs6 sm3 md2 xl4>
                  <v-layout wrap justify-center>
                    <v-flex xs12 text-left>
                      <span class="itemKey">Apiary Code</span>
                    </v-flex>
                    <v-flex xs12 text-left>
                      <span class="itemValue">
                        {{ storage.code }}
                        <span v-if="!storage.code">-</span>
                      </span>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs6 sm3 md1 xl4>
                  <v-layout wrap justify-center>
                    <v-flex xs12 text-left>
                      <span class="itemKey">Hive Count</span>
                    </v-flex>
                    <v-flex xs12 text-left>
                      <span class="itemValue">
                        {{ storage.colonycount }}
                      </span>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs6 sm3 md1 xl4 pl-md-4 pl-xl-0>
                  <v-layout wrap justify-center>
                    <v-flex xs12 text-left>
                      <span class="itemKey">Is Full</span>
                    </v-flex>
                    <v-flex xs12 text-left>
                      <span class="itemValue">
                        <span v-if="storage.isfull">Yes</span>
                        <span v-else>No</span>
                      </span>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs6 sm3 md2 xl4>
                  <v-layout wrap justify-center>
                    <v-flex xs12 text-left>
                      <span class="itemKey">Created Date</span>
                    </v-flex>
                    <v-flex xs12 text-left>
                      <span class="itemValue">
                        {{ formatDate(storage.createddate) }}
                      </span>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 sm8 md4 xl4>
                  <v-layout wrap justify-center>
                    <v-flex xs12 text-left>
                      <span class="itemKey">Location</span>
                    </v-flex>
                    <v-flex xs12 text-left>
                      <span class="itemValue">
                        {{ storage.locationname }}
                        <span v-if="!storage.locationname">-</span>
                      </span>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm2 md1 xl12 text-right align-self-center>
              <v-layout wrap justify-end py-2 py-sm-0 py-xl-2>
                <v-flex xs6 sm12 xl2 px-2 px-sm-0 px-lg-0 px-xl-2>
                  <v-btn
                    block
                    tile
                    small
                    depressed
                    color="#1885f2"
                    :ripple="false"
                    :to="'/Apiary/' + storage._id"
                    class="itemValue"
                  >
                    <span style="color: #ffffff"> View </span>
                  </v-btn>
                </v-flex>
                <v-flex
                  xs6
                  sm12
                  xl2
                  px-2
                  px-sm-0
                  px-lg-0
                  px-xl-2
                  pt-sm-2
                  pt-xl-0
                >
                  <v-btn
                    block
                    tile
                    small
                    depressed
                    outlined
                    color="#FF1313"
                    :ripple="false"
                    @click="deleteDialogue = true"
                    class="itemValue"
                  >
                    <span style="color: #ff1313"> Delete </span>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: ["storage"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
      msg: null,
      deleteDialogue: false,
    };
  },
  methods: {
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      dt = dt.toString();
      day = day < 10 ? "0" + day : day;
      var strTime = day + " " + dt.slice(4, 7) + " " + year;
      return strTime;
    },
    deleteApiary() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/cluster/remove",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.storage._id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.deleteDialogue = false;
            this.$emit("stepper", {
              type: "Delete",
              getData: true,
              pageResponse: response.data,
            });
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
  },
};
</script>