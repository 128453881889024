<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#FDCE48"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#FDCE48" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #000">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12 text-left>
        <span class="itemHeading"> Apiary List </span>
      </v-flex>
    </v-layout>

    <v-layout wrap justify-end pt-4>
      <v-flex xs12 sm4 md2 xl2 text-left pt-2 pt-sm-0 px-md-1>
        <v-menu
          ref="fromDatePicker"
          v-model="fromDatePicker"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              outlined
              dense
              clearable
              v-model="fromDate"
              label="From Date"
              v-bind="attrs"
              v-on="on"
              hide-details
              color="#FDCE48"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="fromDate"
            no-title
            @input="fromDatePicker = false"
          ></v-date-picker>
        </v-menu>
      </v-flex>
      <v-flex xs12 sm4 md2 xl2 text-left pt-2 pt-sm-0 pl-md-1>
        <v-menu
          ref="toDatePicker"
          v-model="toDatePicker"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              outlined
              dense
              clearable
              v-model="toDate"
              label="To Date"
              v-bind="attrs"
              v-on="on"
              hide-details
              color="#FDCE48"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="toDate"
            no-title
            @input="toDatePicker = false"
          ></v-date-picker>
        </v-menu>
      </v-flex>
      <v-flex xs12 sm4 md3 xl2 text-left pt-2 pt-sm-0 pl-sm-2 align-self-center>
        <v-form @submit.prevent="appSearch">
          <v-text-field
            class="searchBox"
            v-model="searchKey"
            prepend-inner-icon="mdi-magnify"
            placeholder="Search by location"
            solo
            flat
            hide-details
            color="#FDCE48"
          ></v-text-field>
        </v-form>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-start py-4>
      <v-flex xs12 lg12 xl6 pa-1 v-for="(item, i) in apiaries" :key="i">
        <ApiariesItem v-bind:storage="item" @stepper="winStepper" />
      </v-flex>
      <v-flex xs12 v-if="apiaries.length < 1">
        <span class="itemKey"> No data available </span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center v-if="pages > 1">
      <v-flex xs12>
        <v-pagination
          v-model="currentPage"
          :length="pages"
          :total-visible="7"
          color="#919191"
        ></v-pagination>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import ApiariesItem from "./apiariesItem";
export default {
  components: {
    ApiariesItem,
  },
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      apiaries: [],
      pages: 0,
      count: 20,
      currentPage: this.$route.query.page ? Number(this.$route.query.page) : 1,
      searchKey: this.$route.query.searchKey
        ? this.$route.query.searchKey
        : null,
      fromDate: this.$route.query.from,
      toDate: this.$route.query.to,
      fromDatePicker: false,
      toDatePicker: false,
    };
  },
  watch: {
    currentPage() {
      this.$router.push({
        path: "/Apiary",
        query: {
          page: this.currentPage,
        },
      });
    },
    fromDate() {
      this.$router.push({
        path: "/Apiary",
        query: {
          page: this.currentPage,
          from: this.fromDate,
          to: this.$route.query.to,
        },
      });
    },
    toDate() {
      this.$router.push({
        path: "/Apiary",
        query: {
          page: this.currentPage,
          from: this.$route.query.from,
          to: this.toDate,
        },
      });
    },
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    appSearch() {
      if (!this.searchKey) this.searchKey = null;
      this.$router.push({
        path: "/Apiary",
        query: {
          searchKey: this.searchKey,
        },
      });
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/cluster/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          user: this.$route.query.user,
          keyword: this.searchKey,
          page: this.$route.query.page,
          count: this.count,
          createdfrom: this.$route.query.from,
          createdto: this.$route.query.to,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.apiaries = response.data.data;
            this.pages = response.data.pages;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    winStepper(windowData) {
      if (windowData.type == "Delete") {
        if (windowData.pageResponse.status) {
          this.msg = "Apiary Deleted Successfully";
          this.showSnackBar = true;
          if (windowData.getData) this.getData();
        } else {
          this.msg = "Apiary not Deleted";
          this.showSnackBar = true;
        }
      }
    },
  },
};
</script>